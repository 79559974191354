<script>
import { required } from "vuelidate/lib/validators";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import UserService from '@/services/user-service'
/**
 * Users Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      
      title: "Users",
      items: [
        {
          text: "Admin"
        },
        {
          text: "Users",
          active: true
        }
      ],
       //Paginacao
       pagination: {
        numberOfElements: 0,
        totalPages: 0,
        totalElements: 0,
        last: true,
        first: true,
        number: 0,
        size: 10,
      },
      paginate: true,
      params: {
        size: 10,
        sort: "name",
      },

      usersData: [
        
      ],
      users: {
        name: "",
        birth: "",
        anotations: ""
      },
      submitted: false,
      showmodal: false
    };
  },
  
  validations: {
    users: {
      name: { required },
      anotations: { required },
      birth: { required },
    }
  },
  created() {
      
    this.service = new UserService(this.$http)
    this.charge()
  },
  methods: {
    charge() {
        this.service.list(this.params).then((res) => {
            console.log(res)
            this.usersData = res.data.content
            this.pagination.totalPages = res.data.totalPages;
            this.pagination.totalElements = res.data.totalElements;
            this.pagination.last = res.data.last;
            this.pagination.first = res.data.first;
            this.pagination.number = res.data.number;
            this.pagination.size = res.data.size;
            this.pagination.numberOfElements = res.data.numberOfElements;

            this.paginate = res.data.totalPages > 0;
            this.loading = false;
        }),(err) => {
            console.error(err)
        }
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const name = this.users.name;
        const anotations = this.users.anotations;
        const birth = this.users.birth;

        this.service.save({
          name,
          birth,
          anotations,
        }).then((ret) => {
            console.log(ret)
            this.charge()
            this.showmodal = false;
        })
        // this.usersData.push({
        //   name,
        //   birth,
        //   anotations,
        // });
        // this.showmodal = false;
      }
      this.submitted = false;
      this.users = {};
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.contacts = {};
    },

    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeSize() {
      this.params.page = 0;
      this.charge();
    },
    gotoPage(pageNum) {
      this.params.page = pageNum;
      if (this.search && this.search.length > 2) {
        this.find();
      } else {
        this.charge();
      }
    },
    edit(user) {
      
      this.user = user;
      this.showmodal = true;

    },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- <div>
              <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Adicionar Novo
              </a>
            </div> -->
            <div class="table-responsive mt-3">
              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Roles</th>
                    <th>Status</th>
                    <th style="width: 120px;">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in usersData" :key="index">
                    <td>{{item.name}}</td>
                    <td>{{item.email}}</td>
                    <td>
                      <template v-for="role in item.roles">
                      {{role.description + " "}}
                      </template>
                    </td>
                    <td>{{item.isEnabled? "Ativo": "Inativo" }}</td>
                    <td>
                      <!-- <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        @click="edit(item)"
                        title="Edit"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Inativa"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-card-footer
                v-if="paginate"
                class="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div class="row">
                  <div class="col-md-11">
                    <ul class="pagination justify-content-left">
                      <li class="page-item">
                        <a
                          v-if="!pagination.first"
                          type="button"
                          class="page-link btn-dark waves-effect waves-light"
                          @click="gotoPage(pagination.number - 1)"
                          >Anterior</a
                        >
                      </li>
                      <li class="page-item">
                        <a
                          v-for="num in pagination.totalPages"
                          :key="num"
                          :class="num - 1 === pagination.number ? 'active' : ''"
                          class="page-link btn-dark waves-effect"
                          @click="gotoPage(num - 1)"
                          >{{ num }}</a
                        >
                      </li>
                      <li class="page-item">
                        <a
                          v-if="!pagination.last"
                          type="button"
                          class="page-link btn-dark waves-effect waves-light"
                          @click="gotoPage(pagination.number + 1)"
                          >Próxima</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-1 justify-content-left">
                    <select
                      v-model="params.size"
                      class="form-control"
                      @change="changeSize"
                    >
                      <option class="selected" selected>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </div>
                </div>
              </b-card-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      :title="$t('all.user')"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            id="name"
            v-model="users.name"
            type="text"
            class="form-control"
            placeholder="Enter name"
            :class="{ 'is-invalid': submitted && $v.users.name.$error }"
          />
          <div
            v-if="submitted && !$v.users.name.required"
            class="invalid-feedback"
          >Name is required</div>
        </div>
        <div class="form-group">
          <label for="exampleInputBirth1">Birth</label>
          <input
            id="birth"
            v-model="users.birth"
            type="date"
            name="birth"
            class="form-control"
            placeholder="Enter birth"
            :class="{ 'is-invalid': submitted && $v.users.birth.$error }"
          />
          <div v-if="submitted && $v.users.birth.$error" class="invalid-feedback">
            <span v-if="!$v.users.birth.required">Birth is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputAnotations">Anotations</label>
          <input
            id="anotations"
            v-model="users.anotations"
            type="text"
            name="anotations"
            class="form-control"
            placeholder="Enter anotations"
            :class="{ 'is-invalid': submitted && $v.users.anotations.$error }"
          />
          <div v-if="submitted && $v.users.anotations.$error" class="invalid-feedback">
            <span v-if="!$v.users.birth.required">anotations is required</span>
          </div>
        </div>
        
        <div class="text-right">
          <button type="submit" class="btn btn-success">Save</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">Cancel</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>